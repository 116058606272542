<template>
  <div class="home">
    <!-- <el-container>
      <el-header>
        <Header></Header>
      </el-header>
      <el-container>
        <el-aside width="200px">
          <el-scrollbar style="height:100%">
            <Aside></Aside>
          </el-scrollbar>
        </el-aside>
        <el-main> -->
          <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>活动管理</el-breadcrumb-item>
          </el-breadcrumb> -->
          <!-- <transition name="el-fade-in">
            <router-view></router-view>
          </transition>
        </el-main>
      </el-container>
    </el-container> -->

    <!-- 第二布局方案 -->
    <el-container>
      <el-aside :class="isAsideShow">
        <Aside></Aside>
      </el-aside>
      <el-container>
        <el-header>
          <Header></Header>
        </el-header>
        <el-main>
          <!-- <transition name="el-fade-in"> -->
            <keep-alive>
            <router-view v-if="$route.meta.keepalive"></router-view>
            </keep-alive>
             <router-view v-if="!$route.meta.keepalive"></router-view>
            
          <!-- </transition> -->
        </el-main>
      </el-container>
    </el-container>
    <progressModle :show='isShowProgress' :progressTips='progressTipsData'></progressModle>
  </div>
</template>

<script>
import Aside from '@/components/Aside'
import Header from '@/components/Head'
export default {
  name: 'home',
  components: {
    Aside,
    Header
  },
  data() {
    return {
      isAsideShow: 'asides',
      isShowProgress: false,
      progressTipsData: '文件上传中······'
    }
  },
  watch: {
    // $route: {
    //   immediate: true,
    //   handler(newVal, oldVal) {
    //     console.log(newVal,oldVal)
    //   }
    // },
    '$store.state.isCollapse'(newVal, oldVal) {
      switch(newVal) {
        case true:
          this.isAsideShow = 'asideHidden';
          break;
        case false:
          this.isAsideShow = 'asideShow';
          break;
      }
    },
    "$store.state.isProgress"(val) {
      this.isShowProgress = val;
    },
    "$store.state.progressTips"(val) {
      this.progressTipsData = val || '文件上传中······';
    }
  }
}
</script>

<style lang="less">
  @import '../styles/Home';
  .home {
    width: 100%;
    height: 100vh;
    overflow: hidden;

    .el-container {
      width: 100%;
      height: 100%;
    }

    .el-header {
      background-color: #fff;
      color: #333;
      padding: 0px;
    }
    
    // .el-aside {
    //   height: calc(~'100vh - 60px');
    //   background-color: rgb(84, 92, 100);
    //   color: #333;
    //   .el-scrollbar__wrap {
    //     overflow-x: hidden;
    //   }
    // }

    .el-aside {
      width: 180px;
      height: 100%;
      background-color: #16222E;
      color: #333;
    }

    .asides {
      width: 180px!important;
    }

    .asideShow {
      width: 64px;
      animation: myshow 1s forwards;
      -webkit-animation: myshow 1s forwards;
    }

    .asideHidden {
      width: 180px;
      animation: myhidden 1s forwards;
      -webkit-animation: myhidden 1s forwards;
    }
    
    @keyframes myshow
    {
        from {width: 64px;}
        to {width: 180px;}
    }

    @keyframes myhidden
    {
        from {width: 180px;}
        to {width: 64px;}
    }

    @-webkit-keyframes myshow
    {
        from {width: 64px;}
        to {width: 260px;}
    }

    @-webkit-keyframes myhidden
    {
        from {width: 260px;}
        to {width: 64px;}
    }
    
    .el-main {
      background-color: #F4F7FC;
      height: calc(100vh - 60px);
      overflow: hidden;
      padding: 10px;
    }

    .mainBtn {
      background-color: #1282FF!important;
      color: #fff!important;
      &:hover {
        color: #fff!important;
        background-color: #1281ffdc!important;
      }
    }

    .mainColor {
      color: #1282FF;
      cursor: pointer;
    }

    .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .el-table {
      font-size: 12px;
      th {
        color: #000;
      }
      /* el-table列数据为空自动显示-- */
      .cell:empty::before{
        content:'/';
        color:gray;
      }
    }
    
    // 隐藏模块
    .hiddenM {
      display: none;
    }

  }
</style>