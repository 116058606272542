<template>
  <el-row class="Head">
    <div class="first_col sub_head">
      <!-- <span class="theme">优课学堂<br>后台管理系统</span> -->
      <!-- <el-button type="primary" class="collapseBtn" @click="collEvent">伸缩</el-button> -->
      <img class="collapseBtn" @click="collEvent" src="../assets/img/navbar.png" alt="">
      <span class="headStyle">{{headTitle}}</span>
    </div>
    <div class="sec_col sub_head">
      <!-- <el-input
        class="search_input"
        placeholder="请输入内容"
        prefix-icon="el-icon-search"
        v-model="searchInput">
      </el-input> -->
      <div class="eMail_area">
        <img class="eMail" @click="jumpTask" src="../assets/img/task.png" alt="">
      </div>
      <div class="head_name">
        <img @click="jumpPersonal" class="el_avatar" src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"/>
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            {{userName}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="personal">个人中心</el-dropdown-item>
            <el-dropdown-item command="signout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </el-row>
</template>

<script>
export default {
  name: 'Head',
  data() {
    return {
      searchInput: '',
      isCollapse: false,
      headTitle: '首页',
      headPic: '',
      userName: '',
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        // console.log(newVal,oldVal)
        this.headTitle = newVal.meta.unit;
      }
    }
  },
  created() {
    let originUrl = location.origin;
    let userData = JSON.parse(sessionStorage.getItem('loginData')).user_info;
    this.headPic = originUrl + userData.avatar;
    this.userName = userData.username;
  },
  methods: {
    // 控制导航菜单伸缩
    collEvent() {
      this.isCollapse = !this.isCollapse;
      this.$store.commit('collapseEvent', this.isCollapse)
    },
    handleCommand(command) {
      if(command == 'signout') {
        this.logoutEvent();
      } else if(command == 'personal') {
        this.jumpPersonal();
      }
    },
    jumpTask() {
      this.$router.push('/Home/taskCenter');
      this.$store.commit('notActiveEvent', true);
      sessionStorage.removeItem('key')
    },
    jumpPersonal() {
      this.$router.push('/Home/personalCenter');
      this.$store.commit('notActiveEvent', true);
      sessionStorage.removeItem('key')
    },
    // 退出登录
    logoutEvent() {
      this.$confirm('是否退出系统, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$request.logout().then(res => {
          if(res.data.code == 0) {
            sessionStorage.clear();
            this.$router.push({ path: "/Login" });
            this.Success('退出成功')
          } else if(res.data.code == 1) {
            this.Error('退出失败')
          }
        })
      }).catch(()=>{})
    }
  },
}
</script>

<style lang="less">
  .Head {
    height: 100%;
    display: flex;
    // box-shadow: 26px -7px 10px -19px #888888 inset;
    .sub_head {
      flex: 1;
      height: 100%;
      .collapseBtn {
        margin-left: 20px;
        cursor: pointer;
      }
      .theme {
        font-weight: 700;
      }
      .headStyle {
        font-size: 18px;
        font-weight: 600;
        color: #16222E;
        margin-left: 15px;
      }
    }
    .first_col {
      display: flex;
      align-items: center;
    }
    .sec_col {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      box-sizing: border-box;
      padding-right: 30px;
      .search_input {
        width: 200px;
        .el-input__inner {
          border: 1px solid #D8DDE5;
          border-radius: 19px;
        }
      }
      .eMail_area {
        height: 16px;
        padding: 0px 30px;
        // border-left: 1px solid #ECEFF4;
        border-right: 1px solid #ECEFF4;
        margin: 0px 30px;
        .eMail {
          cursor: pointer;
          width: 22px;
          height: 16px;
        }
      }
      .head_name {
        display: flex;
        align-items: center;
        .el_avatar {
          width: 40px;
          height: 40px;
          margin-right: 12px;
          cursor: pointer;
          border-radius: 50%;
        }
        .el-dropdown-link {
          cursor: pointer;
          font-size: 18px;
          color: #454545;
          font-weight: 600;
        }
        .el-icon-arrow-down {
          font-size: 12px;
        }
      }
      .login_out {
        width: 30px;
        height: 30px;
        background-color: red;
      }
    }
  }
</style>