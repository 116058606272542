<template>
  <div class="Aside">
    <div class="logoArea">
      <img class="h_logo" src="../assets/img/web_logo.png" alt="" v-show="!isCollapse">
      <img class="h_logo_two" src="../assets/img/web_little_logo.png" alt="" v-show="isCollapse">
      <!-- <span>优课学堂</span> -->
    </div>
    <!-- <vue-scroll> -->
    <el-scrollbar style="height:100%">
      <el-menu
        :collapse="isCollapse"
        :unique-opened="true"
        :default-active="isActives"
        class="el-menu-vertical-demo"
        @select="localPath"
        @open="handleOpen"
        @close="handleClose"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b">
        <template v-for="(item, index) in allmenu">
          <el-submenu :index="`${index+1}`" :key="index" v-if="item.children">
            <template slot="title">
              <!-- <i class="el-icon-location"></i> -->
              <img class="icon_aside" :src="`${require('../assets/img/'+item.icon)}`" alt="">
              <span>{{item.name}}</span>
            </template>
            <template v-for="(ite, ind) in item.children">
              <el-menu-item :key="ind" v-if="ite.path" :index="`${index+1}-${ind+1}`" @click="routeEvent(ite.path)">
                <!-- <i class="el-icon-setting"></i> -->
                <span slot="title">{{ite.name}}</span>
              </el-menu-item>
            </template>
          </el-submenu>
          <el-menu-item :key="index" :index="`${index+1}`" @click="routeEvent(item.path)" v-else>
            <!-- <i class="el-icon-setting"></i> -->
            <img class="icon_asides" :src="`${require('../assets/img/'+item.icon)}`" alt="">
            <span slot="title">{{item.name}}</span>
          </el-menu-item>
        </template>
      </el-menu>
    </el-scrollbar>
    <!-- </vue-scroll> -->
  </div>
</template>

<script>
export default {
  name: 'Aside',
  data() {
    return {
      isActives: '1',
      isCollapse: false,
      allmenu: [
        // {
        //   name: '首页',
        //   url: '/Home/index',
        //   imgUrl: require('../assets/img/index.png')
        // },
        // {
        //   name: '学校管理',
        //   imgUrl: require('../assets/img/schoolManagement.png'),
        //   menus: [
        //     {
        //       name: '学校列表',
        //       url: '/Home/schoolManagement/schoolList'
        //     },
        //     {
        //       name: '教学计划',
        //       url: '/Home/schoolManagement/teachPlan'
        //     },
        //     // {
        //     //   name: '学生管理',
        //     //   url: '/Home/schoolManagement/studentManagement'
        //     // },
        //     {
        //       name: '合同管理',
        //       url: ''
        //     }
        //   ]
        // },
        // {
        //   name: '审核管理',
        //   imgUrl: require('../assets/img/AuditManagement.png'),
        //   menus: [
        //     {
        //       name: '订单管理',
        //       url: '/Home/auditManagement/orderManagement'
        //     },
        //     {
        //       name: '审核管理',
        //       url: '/Home/auditManagement/auditManagements'
        //     }
        //   ]
        // },
        // {
        //   name: '专业库管理',
        //   imgUrl: require('../assets/img/AuditManagement.png'),
        //   menus: [
        //     {
        //       name: '专业库管理',
        //       url: '/Home/library/libraryManagement'
        //     }
        //   ]
        // },
        // {
        //   name: '角色管理',
        //   imgUrl: require('../assets/img/RoleManagement.png'),
        //   menus: [
        //     {
        //       name: '角色管理',
        //       url: '/Home/role/roleManagement'
        //     },
        //     {
        //       name: '用户管理',
        //       url: '/Home/role/userManagement'
        //     }
        //   ]
        // },
        // {
        //   name: '日志管理',
        //   imgUrl: require('../assets/img/LogManagement.png'),
        //   menus: [
        //     {
        //       name: '登录日志',
        //       url: '/Home/logManagement/loginLog'
        //     },
        //     {
        //       name: '操作日志',
        //       url: '/Home/logManagement/operationLog'
        //     }
        //   ]
        // },
        // {
        //   name: '版本管理',
        //   imgUrl: require('../assets/img/RoleSelection.png'),
        //   menus: [
        //     {
        //       name: '版本管理',
        //       url: '/Home/version/versionManagement'
        //     }
        //   ]
        // },
        // {
        //   name: '测试',
        //   menus: [
        //     {
        //       name: '测试页',
        //       url: '/Home/Test'
        //     },
        //     {
        //       name: '订单管理',
        //       url: '/Home/orderManagement'
        //     }
        //   ]
        // }
      ]
    }
  },
  watch: {
    '$store.state.isCollapse'(newVal, oldVal) {
      this.isCollapse = newVal;
    },
    '$store.state.isNotActive'(newVal, oldVal) {
      if(newVal) {
        this.isActives = '';
      }
    },
    '$store.state.asideMenuData'(newVal, oldVal) {
      this.allmenu = newVal;
    },
    '$store.state.watchKey'(newVal, oldVal) {
      this.isActives = newVal;
    }
  },
  created() {
    this.isActives = sessionStorage.getItem("key") || '';
    this.allmenu = JSON.parse(sessionStorage.getItem('asideMenuData')) || [];
  },
  methods: {
    handleOpen(key, keyPath) {
    },
    handleClose(key, keyPath) {
    },
    localPath(key, keyPath) {
      // console.log(key, keyPath);
      this.isActives = key;
      sessionStorage.setItem("key", key);
      this.$store.commit('notActiveEvent', false);
    },
    routeEvent(url) {
      // console.log(url,8888)
      this.$router.push('/Home'+url)
    }
  }
}
</script>

<style lang="less">
  .Aside {
    height: calc(~'100% - 120px');
    .logoArea {
      z-index: 10;
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #16222E;
      .h_logo {
        width: 132px;
        height: 35px;
      }
      .h_logo_two {
        width: 36px;
        height: 36px;
      }
      .theme {
        color: #fff;
        font-weight: 700;
      }
    }
    .el-scrollbar {
      // margin-top: 60px;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
    & > ul {
      height: 100%;
    }
    .el-menu {
      width: 100%;
      border: 0 none;
      background-color: transparent!important;
      .el-menu-item {
        // padding-left: 53px!important;
        background-color: inherit!important;;
      }
      .icon_asides {
        width: 18px;
        height: 18px;
        margin-right: 5px;
      }
      .el-submenu {
        .el-submenu__title {
          // padding-left: 53px!important;
          background-color: inherit!important;
          &:active {
            background-color: #213141!important;
          }
          .icon_aside {
            width: 18px;
            height: 18px;
            margin-right: 5px;
          }
        }
        .el-menu-item {
          color: #8A96A2!important;
        }
        .is-active {
          color: rgb(255, 208, 75)!important;
        }
      }
    }
  }
</style>